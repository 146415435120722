import axios from '@/api/axios-date-selection2.js'
import calendarAxios from '@/api/axios-calendar'
import axiosFortune from '@/api/axios-fortune-telling.js'
import router from '@/router'
import {
  CandidateRequestSchema,
  candidateSchema,
} from '@/constants//date-selection/candidate-schema.js'
import auspiciousSchema from '@/constants//date-selection/auspicious-schema.js'
import auspiciousTh from '@/constants/date-selection/auspicious-th'
import moment from 'moment'
import { downloadCsv, objectsToCsv } from '../../helpers/csv_exporter'
import { auspiciousTypes } from '../../constants/date-selection/date-selection-consts'

const state = {
  requests2: [],
  request2: null,
  totalRequestPage: 1,
  currentRequestPage: 1,
  totalRequest: 0,
  requestPageSize: 25,
  isLoadingRequests: false,
  hasFetchRequests: false,
  auspiciousDates: [],
  submitType: null,
  isSubmittingAus: false,
  queryParams: {},
  selectedServices: auspiciousTypes.map(({ en, value }) => ({
    name: en,
    value,
  })),
}

const getters = {
  requests2: (state) => {
    return state.requests2
  },
  request2: (state) => {
    return state.request2
  },
  currentRequestPage: (state) => {
    return state.currentRequestPage
  },
  totalRequestPage: (state) => {
    return state.totalRequestPage
  },
  auspiciousDates: (state) => {
    return state.auspiciousDates
  },
  isLoadingRequests: (state) => {
    return state.isLoadingRequests
  },
  submitType: (state) => {
    return state.submitType
  },
  isSubmittingAus(state) {
    return state.isSubmittingAus
  },
  hasFetchRequests(state) {
    return state.hasFetchRequests
  },
  selectedServices(state) {
    return state.selectedServices
  },
}

const mutations = {
  setRequests2(state, requests) {
    state.requests2 = requests
  },
  setQueryParams(state, params) {
    state.queryParams = params
  },
  setRequest2(state, request) {
    state.request2 = request
  },
  setSubmitType(state, type) {
    state.submitType = type
  },
  clearRequest2(state) {
    state.request2 = null
  },
  setRequestPage(state, page) {
    state.currentRequestPage = page
  },
  setTotalRequest(state, totalRequest) {
    state.totalRequest = totalRequest
  },
  setTotalRequestPage(state, page) {
    state.totalRequestPage = page
  },
  isLoadingRequests(state, status) {
    state.isLoadingRequests = status
  },
  setAuspiciousDates(state, auspiciousDates) {
    state.auspiciousDates = auspiciousDates
  },
  clearAuspiciousDates(state) {
    state.auspiciousDates = []
  },
  isSubmittingAus(state, newState) {
    state.isSubmittingAus = newState
  },
  setHasFetchRequests(state, newState) {
    state.hasFetchRequests = newState
  },
  setSelectedServices(state, newState) {
    state.selectedServices = newState
  },
}

function cleanParams(params) {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([_, v]) => v !== null && v !== undefined && v !== ''
    )
  )
}

const actions = {
  getAllRequest2: async (
    { commit, getters, state },
    { user_id, user_name, status, type, start_date, end_date, isSearching }
  ) => {
    let auspiciousType = ''
    if (Array.isArray(type)) {
      auspiciousType = type ? type.join(',') : null
    } else {
      auspiciousType = type
    }

    let params = {
      page: getters.currentRequestPage,
      limit: 10,
      user_id,
      user_name,
      status,
      type: auspiciousType,
      start_date: start_date ? moment(start_date).format('yyyy-MM-DD') : null,
      end_date: end_date ? moment(end_date).format('yyyy-MM-DD') : null,
    }

    if (isSearching) {
      params['page'] = 1
      commit('setRequestPage', 1)
      commit('setQueryParams', {
        user_id,
        user_name,
        status,
        type: params.type,
        start_date: params.start_date,
        end_date: params.end_date,
      })
    } else {
      params = {
        ...params,
        ...state.queryParams,
        page: getters.currentRequestPage,
      }
    }

    params = cleanParams(params)

    commit('isLoadingRequests', true)
    await axios
      .get('/auspicious-dates', { params })
      .then((res) => {
        if (res.data.auspicious_list !== null) {
          commit('setRequests2', res.data.auspicious_list)
        } else {
          commit('setRequests2', [])
        }
        commit('setTotalRequestPage', res.data.meta.pagination.pages)
        commit('setTotalRequest', res.data.meta.pagination.total)
        commit('isLoadingRequests', false)
      })
      .catch((err) => {
        console.error('Error fetching requests:', err)
        commit('isLoadingRequests', false)
      })
  },
  setRequestPage: async ({ commit, dispatch }, pageNumber) => {
    commit('setRequestPage', pageNumber)
    dispatch('getAllRequest2', { isSearching: false }) // Fetch the data for the new page using the stored query params
  },
  getRequest2: async ({ commit, dispatch, getters }, requestId) => {
    commit('clearRequest2')
    commit('clearAuspiciousDates')
    await axios.get('/auspicious-dates/' + requestId).then(async (res) => {
      let auspicious = res.data
      let { type, status, remark = null } = res.data
      let { submitType } = getters
      let { candidate_dates, selected_candidate_date } = auspicious[type]
      let auspicious_dates = []
      // Find Auspicious Date is Single or Multiple
      if (typeof auspicious[type].auspicious_date !== 'undefined') {
        if (auspicious[type].auspicious_date?.engagement == null) {
          auspicious_dates = null
        } else {
          auspicious_dates = [auspicious[type].auspicious_date]
        }

        if (['new_wallet', 'exam'].includes(type)) {
          auspicious_dates = [auspicious[type].auspicious_date]
        }
      } else {
        auspicious_dates = auspicious[type].auspicious_dates
      }

      if (submitType == 'candidate') {
        auspicious_dates = candidate_dates
      }
      // Get Auspicious Object
      auspicious_dates = formatAuspicious(
        auspicious_dates,
        submitType,
        type,
        status
      )

      let selected_auspicious = auspicious_dates
      if (
        submitType !== null &&
        selected_auspicious !== null &&
        Array.isArray(selected_auspicious) &&
        selected_auspicious.length !== 0
      ) {
        selected_auspicious = selected_auspicious.map((v) => {
          let newObject = {}
          let array = []
          for (const objectKey in v) {
            newObject = {
              ...v[objectKey],
              key: objectKey,
              name: auspiciousTh[objectKey],
              time: `${v[objectKey].start_time}~${v[objectKey].end_time}`,
            }
            array.push(newObject)
          }
          return array
        })
      }

      if (typeof auspicious[type].candidate_dates !== 'undefined') {
        auspicious = { ...auspicious, candidate_dates, selected_candidate_date }
      }
      // Provisioning
      auspicious = {
        ...auspicious,
        auspicious_dates,
        selected_auspicious,
        requireBlessing: type === 'exam',
        exam_info:
          type === 'exam' ? getExamInfo(auspicious[type].detail) : null,
        new_wallet_info:
          type === 'new_wallet'
            ? getNewWalletInfo(auspicious[type].detail)
            : null,
        facing_direction: auspicious[type].detail.facing_direction
          ? auspicious[type].detail.facing_direction
          : null,
      }

      if (type === 'new_car') {
        const { data } = await calendarAxios.get('/colors')
        auspicious.suggestedColors = [
          {
            label: 'Colors',
            colors: data,
          },
        ]
        const [one, two] = auspicious[type].lucky_colors
        auspicious.selectedColors = {
          auspiciousOne: one || null,
          auspiciousTwo: two || null,
          inauspiciousName: auspicious[type].strongest_element,
        }
        auspicious.selectedNumbers = auspicious[type].lucky_numbers || []
      }

      commit('setRequest2', auspicious)

      commit('setAuspiciousDates', auspicious_dates)
      commit('setRemark', remark == null ? '' : remark)
      commit('setAuspiciousType', type)
      commit('clearSelectedAuspicious')
      dispatch('getPersonBaziProfile', {
        detail: auspicious[type].detail,
        type: auspicious.type,
      })
      dispatch('initCurrentAuspicious')
    })
  },
  deleteRequest2: async ({ dispatch }, requestId) => {
    await axios
      .delete('/auspicious-dates/' + requestId)
      .then((res) => {
        dispatch('setAlertMessage', {
          message: 'ลบ Request สำเร็จ',
          type: 'success',
        })
      })
      .catch((err) => {
        dispatch('setAlertMessage', {
          message: 'เกิดข้อผิดพลาด',
          type: 'error',
        })
      })
  },
  getPersonBaziProfile: async (
    { commit, getters, dispatch },
    { detail, type }
  ) => {
    let persons = getPersons(detail, type)
    let promises = []
    let { auspiciousType, submitType } = getters

    persons.forEach((e, index) => {
      let promise = new Promise((resolve, reject) => {
        function dobHaveNullString(dobString) {
          return dobString.includes('null')
        }

        if (e.dob !== null && !dobHaveNullString(e.dob)) {
          let params = {}
          if (e.tob !== 'null') {
            params.time = e.tob
          }
          axiosFortune
            .get(`/bazis/${e.dob}`, { params: params })
            .then((res) => {
              resolve({
                ...e,
                index: index,
                validDob: true,
                isActive: initPersonActiveFilterByType(auspiciousType, index),
                bazi: res.data,
              })
            })
            .catch((err) => {
              console.log(err)
              reject({ ...e })
            })
        } else {
          function convertInvalidDobString(dobString) {
            return dobString.replaceAll('-', '/').replaceAll('null', '--')
          }
          resolve({
            ...e,
            dob: convertInvalidDobString(e.dob),
            validDob: false,
            index: index,
            isActive: initPersonActiveFilterByType(auspiciousType, index),
            bazi: null,
          })
        }
      })
      promises.push(promise)
    })
    await Promise.allSettled(promises).then((data) => {
      let result = data
        .filter((e) => e.status == 'fulfilled')
        .map((e) => e.value)
      promises = []
      result.forEach((p) => {
        if (p.dob) {
          let params = {}
          if (p.tob !== 'null') {
            params.time = p.tob
          }
          let promise = new Promise((resolve, reject) => {
            if (!p.validDob) {
              resolve({ ...p, fiveStructure: null })
              return
            }
            axiosFortune
              .get(`/bazis/${p.dob}/five-structure`, { params: params })
              .then((res) => {
                let { metal, water, wood, fire, earth } = res.data
                let elements = [metal, water, wood, fire, earth]
                resolve({ ...p, fiveStructure: elements })
              })
              .catch((err) => {
                console.log(err)
              })
          })
          promises.push(promise)
        }
      })
      Promise.allSettled(promises).then((data) => {
        const personsWithFiveStructure = data.map((d) => d.value)
        commit('setPersons', personsWithFiveStructure)
      })
    })

    // Fetch calendar if calendar is not init
    if (submitType !== null) {
      let today = new Date()
      let month = today.getMonth() + 1
      let year = today.getFullYear()
      if (getters.calendar.length == 0) {
        await dispatch('getCalendar')
      }
      dispatch('getAuspiciousCalendar', {
        year: year,
        month: month,
      })
    }
  },

  submitAuspicious: async ({ dispatch, getters, commit }) => {
    commit('isSubmittingAus', true)
    let { submitType } = getters

    if (submitType == 'candidate') {
      dispatch('submitByTypeCandidate')
    } else {
      dispatch('submitByTypeAuspicious')
    }
  },

  submitByTypeCandidate: async ({ dispatch, getters, commit }) => {
    let { request2 } = getters
    let selectedList = getters.selectedAuspicious
    // build submit form
    selectedList = selectedList.map((ausArray) => {
      let ausDetail = ausArray.map((a) => {
        let start_time = a.time.split('~')[0]
        let end_time = a.time.split('~')[1]
        let data = {
          [a.key]: {
            date: a.date,
            start_time: start_time,
            end_time: end_time,
          },
        }
        if (a.blessing) data.blessing = a.blessing
        return { ...data }
      })
      let auspiciousItem = Object.fromEntries(
        Object.entries(ausDetail).map(([key, val]) => {
          let keyz = Object.keys(val)[0]
          return [keyz, val[keyz]]
        })
      )
      return {
        ...auspiciousItem,
      }
    })
    let list = selectedList
    let requestForm = CandidateRequestSchema
    requestForm.marriage.candidate_dates = list
    await axios
      .post(`/auspicious-dates/${request2.id}/candidate-dates`, requestForm)
      .then(() => {
        dispatch('setAlertMessage', {
          message: 'Candidate dates are submitted!',
          type: 'success',
        })
        commit('isSubmittingAus', false)

        router.go(-1)
      })
      .catch((err) => {
        dispatch('setAlertMessage', {
          message: 'เกิดข้อผิดพลาด!',
          type: 'error',
        })
        commit('isSubmittingAus', false)
      })
  },

  submitByTypeAuspicious: async ({ dispatch, getters, commit }) => {
    let selectedAuspicious = getters.selectedAuspicious
    let { request2, auspiciousType, remark } = getters
    let validate = {
      isNotValid: false,
      message: '',
    }
    // build submit form
    selectedAuspicious = selectedAuspicious.map((ausArray) => {
      let ausDetail = ausArray.map((a) => {
        let start_time = a.time.split('~')[0]
        let end_time = a.time.split('~')[1]
        let data = {
          [a.key]: {
            date: a.date,
            start_time: start_time,
            end_time: end_time,
          },
        }
        if (auspiciousType == 'exam' && a.blessing == '') {
          validate = {
            isNotValid: true,
            message: 'Please select Blessing text before submitting',
          }
        }
        if (a.blessing) {
          data.blessing_id = a.blessing
          data[a.key].blessing = a.blessing
        }
        return { ...data }
      })
      let auspiciousItem = Object.fromEntries(
        Object.entries(ausDetail).map(([key, val]) => {
          let keyz = Object.keys(val)[0]
          let newVal = { ...val[keyz] }
          if (val.blessing_id) newVal.blessing_id = val.blessing_id
          return [keyz, newVal]
        })
      )
      if (typeof auspiciousItem['exam']?.blessing !== 'undefined') {
        auspiciousItem.blessing_id = auspiciousItem['exam'].blessing
      }
      return {
        ...auspiciousItem,
      }
    })

    if (validate.isNotValid) {
      dispatch('setAlertMessage', {
        message: validate.message,
        type: 'warning',
      })
      commit('isSubmittingAus', false)
      return
    }

    const NOT_ALLOW_MULTIPLE = [
      'marriage',
      'new_wallet',
      'exam',
      'beauty',
      'study_abroad',
      'new_pet',
    ]
    let form
    if (NOT_ALLOW_MULTIPLE.includes(auspiciousType)) {
      form = {
        remark: remark,
        [auspiciousType]: {
          auspicious_date: selectedAuspicious[0],
        },
      }
    } else {
      form = {
        remark: remark,
        [auspiciousType]: {
          auspicious_dates: selectedAuspicious,
          ...(auspiciousType === 'new_car' && {
            lucky_colors: [
              request2.selectedColors.auspiciousOne?.id,
              request2.selectedColors.auspiciousTwo?.id,
            ].filter(Boolean),
            lucky_numbers: request2.selectedNumbers.filter(Boolean),
          }),
        },
      }
    }
    form.is_auto_select = false

    await axios
      .post(`/auspicious-dates/${request2.id}/auspicious-dates`, form)
      .then((res) => {
        dispatch('setAlertMessage', {
          message: 'ส่งฤกษ์สำเร็จ',
          type: 'success',
        })
        commit('isSubmittingAus', false)
        dispatch('getRequest2', request2.id)
      })
      .catch((err) => {
        const { error } = err.response.data

        dispatch('setAlertMessage', {
          message: error || 'เกิดข้อผิดพลาด!',
          type: 'error',
        })
        commit('isSubmittingAus', false)
      })
  },

  // setRequestPage: async ({ commit }, pageNumber) => {
  //   commit('setRequestPage', pageNumber)
  // },
}

export default {
  state,
  mutations,
  actions,
  getters,
}

function getPersons(detail, type) {
  let persons = []
  switch (type) {
    case 'marriage':
      {
        let groomName = `${detail['groom'].first_name} ${detail['groom'].last_name}`
        let brideName = `${detail['bride'].first_name} ${detail['bride'].last_name}`
        let groom = personFactory(
          'เจ้าบ่าว',
          'G',
          groomName,
          detail['groom'].date_of_birth,
          detail['groom'].time_of_birth
        )
        let bride = personFactory(
          'เจ้าสาว',
          'B',
          brideName,
          detail['bride'].date_of_birth,
          detail['bride'].time_of_birth
        )
        let groomFather = personFactory(
          'พ่อของเจ้าบ่าว',
          'P1(GF)',
          '',
          formatDateOfBirth(
            detail['groom_father'].day,
            detail['groom_father'].month,
            detail['groom_father'].year
          ),
          detail['groom_father'].time_of_birth || null
        )
        let groomMother = personFactory(
          'แม่ของเจ้าบ่าว',
          'P2(GM)',
          '',
          formatDateOfBirth(
            detail['groom_mother'].day,
            detail['groom_mother'].month,
            detail['groom_mother'].year
          ),
          detail['groom_mother'].time_of_birth || null
        )
        let brideFather = personFactory(
          'พ่อของเจ้าสาว',
          'P3(BF)',
          '',
          formatDateOfBirth(
            detail['bride_father'].day,
            detail['bride_father'].month,
            detail['bride_father'].year
          ),
          detail['bride_father'].time_of_birth || null
        )
        let brideMother = personFactory(
          'แม่ของเจ้าสาว',
          'P4(BM)',
          '',
          formatDateOfBirth(
            detail['bride_mother'].day,
            detail['bride_mother'].month,
            detail['bride_mother'].year
          ),
          detail['bride_mother'].time_of_birth || null
        )
        persons = [
          groom,
          bride,
          groomFather,
          groomMother,
          brideFather,
          brideMother,
        ]
      }
      break
    case 'marriage_registration':
      {
        let groomName = `${detail['groom'].first_name} ${detail['groom'].last_name}`
        let brideName = `${detail['bride'].first_name} ${detail['bride'].last_name}`
        let groom = personFactory(
          'เจ้าบ่าว',
          'Groom',
          groomName,
          detail['groom'].date_of_birth,
          detail['groom'].time_of_birth
        )
        let bride = personFactory(
          'เจ้าสาว',
          'Bride',
          brideName,
          detail['bride'].date_of_birth,
          detail['bride'].time_of_birth
        )
        persons = [groom, bride]
      }
      break
    case 'new_car':
      {
        persons = detail.members
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`
          return personFactory(
            `เจ้าของรถคนที่ ${index + 1}`,
            `M${index + 1}`,
            name,
            p.date_of_birth,
            p.time_of_birth
          )
        })
      }
      break
    case 'birth':
      {
        let fatherName = `${detail['father'].first_name} ${detail['father'].last_name}`
        let motherName = `${detail['mother'].first_name} ${detail['mother'].first_name}`
        let father = personFactory(
          'บิดา',
          'FT',
          fatherName,
          detail['father'].date_of_birth,
          detail['father'].time_of_birth || null
        )
        let mother = personFactory(
          'มารดา',
          'MT',
          motherName,
          detail['mother'].date_of_birth,
          detail['mother'].time_of_birth || null
        )
        let child = personFactory(
          'บุตร',
          'B',
          '',
          formatDateOfBirth(null, null, detail.baby_year_of_birth),
          null
        )
        child = {
          ...child,
          baby_gender: detail.baby_gender,
          baby_year_of_birth: detail.baby_year_of_birth,
        }
        persons = [father, mother, child]
      }
      break
    case 'gift_icsi':
      {
        let fatherName = `${detail['father'].first_name} ${detail['father'].last_name}`
        let motherName = `${detail['mother'].first_name} ${detail['mother'].first_name}`
        let father = personFactory(
          'Father',
          '',
          fatherName,
          detail['father'].date_of_birth,
          detail['father'].time_of_birth || null
        )
        let mother = personFactory(
          'Mother',
          '',
          motherName,
          detail['mother'].date_of_birth,
          detail['mother'].time_of_birth || null
        )
        persons = [father, mother]
      }
      break

    case 'non_residential_construction':
      {
        persons = detail.members
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`
          return personFactory(
            `เจ้าของที่ดินคนที่ ${index + 1}: ${p.ownership}`,
            `M${index + 1}`,
            name,
            p.date_of_birth,
            p.time_of_birth
          )
        })
      }
      break
    case 'residential_construction':
      {
        persons = detail.members
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`
          return personFactory(
            `เจ้าของบ้านคนที่ ${index + 1}: ${p.ownership}`,
            `M${index + 1}`,
            name,
            p.date_of_birth,
            p.time_of_birth
          )
        })
      }
      break
    case 'house_moving':
      {
        persons = detail.members
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`
          return personFactory(
            `ผู้อาศัยคนที่ ${index + 1}: ${p.ownership}`,
            `M${index + 1}`,
            name,
            p.date_of_birth,
            p.time_of_birth
          )
        })
      }
      break
    case 'contract_signing':
      {
        persons = detail.members
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`
          return personFactory(
            `ผู้ร่วมธุรกิจคนที่ ${index + 1}`,
            `M${index + 1}`,
            name,
            p.date_of_birth,
            p.time_of_birth
          )
        })
      }
      break
    case 'business_opening':
      {
        persons = detail.members
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`
          return personFactory(
            `ผู้เกี่ยวข้องที่ ${index + 1}: ${toUpperCaseLabel(p.ownership)}`,
            `M${index + 1}`,
            name,
            p.date_of_birth,
            p.time_of_birth
          )
        })
      }
      break
    case 'surgery':
      {
        let person = detail.person
        let name = `${person['first_name']} ${person['last_name']}`
        let owner = personFactory(
          'ผู้ร้องขอ',
          'P',
          name,
          person['date_of_birth'],
          person['time_of_birth']
        )
        persons = [owner]
      }
      break
    case 'treatment':
      {
        let person = detail.person
        let name = `${person['first_name']} ${person['last_name']}`
        let owner = personFactory(
          'ผู้ร้องขอ',
          'P',
          name,
          person['date_of_birth'],
          person['time_of_birth']
        )
        persons = [owner]
      }
      break
    case 'cosmetic_surgery':
      {
        let person = detail.person
        let name = `${person['first_name']} ${person['last_name']}`
        let owner = personFactory(
          'ผู้ร้องขอ',
          'P',
          name,
          person['date_of_birth'],
          person['time_of_birth']
        )
        persons = [owner]
      }
      break
    case 'new_wallet':
      {
        let person = detail.person
        let name = `${person['first_name']} ${person['last_name']}`
        let owner = personFactory(
          'ผู้ร้องขอ',
          'P',
          name,
          person['date_of_birth'],
          person['time_of_birth']
        )
        persons = [owner]
      }
      break
    case 'exam':
      {
        let person = detail.person
        let name = `${person['first_name']} ${person['last_name']}`
        let owner = personFactory(
          'ผู้ร้องขอ',
          'P',
          name,
          person['date_of_birth'],
          person['time_of_birth']
        )
        persons = [owner]
      }
      break
    case 'study_abroad':
      {
        let person = detail.person
        let name = `${person['first_name']} ${person['last_name']}`
        let owner = personFactory(
          'ผู้ร้องขอ',
          'P',
          name,
          person['date_of_birth'],
          person['time_of_birth']
        )
        persons = [owner]
      }
      break
    case 'new_pet':
      {
        let person = detail.person
        let name = `${person['first_name']} ${person['last_name']}`
        let owner = personFactory(
          'ผู้ร้องขอ',
          'P',
          name,
          person['date_of_birth'],
          person['time_of_birth']
        )
        persons = [owner]
      }
      break
    case 'product_launch':
      {
        persons = detail.members
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`
          return personFactory(
            `ผู้เกี่ยวข้องที่ ${index + 1}`,
            `M${index + 1}`,
            name,
            p.date_of_birth,
            p.time_of_birth
          )
        })
      }
      break
    default: {
      let person = detail.person
      let name = `${person['first_name']} ${person['last_name']}`
      let owner = personFactory(
        'ผู้ร้องขอ',
        'P',
        name,
        person['date_of_birth'],
        person['time_of_birth']
      )
      persons = [owner]
    }
  }
  return persons
}

function personFactory(title, acronym, name, dob, tob) {
  return {
    title: title,
    acronym: acronym,
    name: name,
    dob: !isEmpty(dob) ? dob : null,
    tob: !isEmpty(tob) ? tob : 'null',
  }
}

// initialize person filter checkbox tick
function initPersonActiveFilterByType(type, index) {
  // index = person
  switch (type) {
    case 'marriage':
      // tick groom and bride
      if (index <= 1) return true
      return false
    case 'new_car':
      return true
    case 'birth':
      return true
    case 'non_residential_construction':
      if (index <= 0) return true
      return true
    case 'residential_construction':
      if (index <= 0) return true
      return true
    case 'house_moving':
      if (index <= 0) return true
      return true
    case 'contract_signing':
      if (index <= 0) return true
      return true
    case 'business_opening':
      if (index <= 0) return true
      return true
    case 'surgery':
      return true
    case 'treatment':
      return true
    case 'cosmetic_surgery':
      return true
    case 'study_abroad':
      return true
    case 'new_pet':
      return true
    case 'product_launch':
      if (index <= 0) return true
      return true
    default:
      return true
  }
}

function isEmpty(obj) {
  return typeof obj == 'undefined' || obj == '' || obj == null || obj == '-'
}

function getNewWalletInfo(detail) {
  return {
    occupation: detail.occupation,
    other_occupation: detail.other_occupation,
  }
}

function getExamInfo(detail) {
  return {
    occupation: detail.occupation,
    other_occupation: detail.other_occupation,
    exam_type: detail.exam_type,
  }
}

function formatAuspicious(auspicious_dates, submitType, type, status) {
  if (auspicious_dates == null || auspicious_dates.length == 0) {
    let returnObj =
      submitType == 'candidate' ? [candidateSchema] : [auspiciousSchema[type]]
    return returnObj
  }

  return auspicious_dates.map((auspicious) => {
    let SPECIAL_TYPE = ['exam', 'new_wallet']
    if (submitType == 'auspicious' && !['completed'].includes(status)) {
      return auspiciousSchema[type]
    }
    if (submitType == 'candidate' && ['date_selecting'].includes(status)) {
      return candidateSchema
    }

    // type have extra detail
    if (SPECIAL_TYPE.includes(type)) {
      let obj = {
        [type]: {
          ...auspicious[type],
          blessing: type == 'exam' ? auspicious.blessing_id : null,
          lucky_colors: auspicious.lucky_colors
            ? auspicious.lucky_colors
            : null,
          wallet_info:
            type == 'new_wallet'
              ? {
                  gift_bag_money_suggestion:
                    auspicious.gift_bag_money_suggestion,
                  wallet_shape_suggestion: auspicious.wallet_shape_suggestion,
                  lucky_colors: auspicious.lucky_colors,
                  star_of_prosperity: auspicious.star_of_prosperity,
                }
              : null,
        },
      }
      return obj
    }
    return {
      ...auspicious,
    }
  })
}

const toUpperCaseLabel = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function formatDateOfBirth(day, month, year) {
  // Ensure day and month are always two digits
  const formattedDay = String(day).padStart(2, '0')
  const formattedMonth = String(month).padStart(2, '0')

  // Return the date in "YYYY-MM-DD" format
  return `${year}-${formattedMonth}-${formattedDay}`
}

export async function downloadRequestDatesCsv(startDate, endDate) {
  let start_date = moment(startDate).format('YYYY-MM-DD')
  let end_date = moment(endDate).format('YYYY-MM-DD')
  let limit = 9999
  let response = await axios.get('/auspicious-dates', {
    params: {
      start_date,
      end_date,
      limit,
    },
  })
  let data = response.data.auspicious_list.map(
    ({
      id,
      display_id,
      user_id,
      user_full_name,
      type,
      amount,
      status,
      contact_name,
      contact_phone,
      day_types,
      preferred_date_ranges,
      purchase_date,
      purchase_time,
      remark,
      slip_url,
      created_at,
    }) => ({
      display_id,
      user_id,
      user_full_name,
      type,
      amount,
      status,
      day_types: day_types.join(','),
      preferred_date_ranges: preferred_date_ranges
        .map(({ start_date, end_date }) => `${start_date} to ${end_date}`)
        .join(','),
      purchase_at: `${purchase_date} ${purchase_time}`,
      slip_url:
        slip_url &&
        `https://storage.googleapis.com/public_dev_storage_numeiang-app/auspicious/${id}/slips/${slip_url}`,
      contact_name,
      contact_phone,
      remark,
      created_at,
    })
  )
  let csvFormat = objectsToCsv(data)
  downloadCsv(csvFormat, `${start_date}_${end_date}_request_dates.csv`)
}
