import axios from '@/api/axios-users2'
import moment from 'moment'

const state = {
  users2: [],
  users2TotalPage: 0,
  users2TotalUser: 0,
  isLoadingUsers2: false,
  allUsers: [],
  usersCountNewUser: [],
  usersCountAge: [],
  usersCountGender: [],
  usersCountRegisterMethod: [],
  usersCountRelationship: [],
  isLoadingUsersCountNewUser: false,
  isLoadingUsersCountAge: false,
  isLoadingUsersCountGender: false,
  isLoadingUsersCountRegisterMethod: false,
  isLoadingUsersCountRelationship: false,
  isExportingUser: false,
}

const mutations = {
  setUsers2(state, value) {
    state.users2 = value
  },
  setUsers2TotalPage(state, value) {
    state.users2TotalPage = value
  },
  setUsers2TotalUser(state, value) {
    state.users2TotalUser = value
  },
  setAllUsers(state, value) {
    state.allUsers = value
  },
  setUsersCountNewUser(state, value) {
    state.usersCountNewUser = value
  },
  setUsersCountAge(state, value) {
    state.usersCountAge = value
  },
  setUsersCountGender(state, value) {
    state.usersCountGender = value
  },
  setUsersCountRegisterMethod(state, value) {
    state.usersCountRegisterMethod = value
  },
  setUsersCountRelationship(state, value) {
    state.usersCountRelationship = value
  },
  setIsLoadingUsers2(state, value) {
    state.isLoadingUsers2 = value
  },
  setIsLoadingUsersCountNewUser(state, value) {
    state.isLoadingUsersCountNewUser = value
  },
  setIsLoadingUsersCountAge(state, value) {
    state.isLoadingUsersCountAge = value
  },
  setIsLoadingUsersCountGender(state, value) {
    state.isLoadingUsersCountGender = value
  },
  setIsLoadingUsersCountRegisterMethod(state, value) {
    state.isLoadingUsersCountRegisterMethod = value
  },
  setIsLoadingUsersCountRelationship(state, value) {
    state.isLoadingUsersCountRelationship = value
  },
  setIsExportingUser(state, value) {
    state.isExportingUser = value
  },
}

const actions = {
  getUsers2: async (
    { commit, getters },
    {
      page = null,
      limit = null,
      id = null,
      name = null,
      phone = null,
      email = null,
    }
  ) => {
    commit('setIsLoadingUsers2', true)
    let params = {
      page: page,
      limit: limit,
      id: id,
      name: name,
      phone: phone,
      email: email,
    }

    if (typeof email == 'string' && email.trim() !== '')
      params.email = email.toLowerCase()
    else params.email = null

    await axios
      .get('/users', {
        params: params,
      })
      .then((res) => {
        if (res.data !== null) {
          let users = res.data.users.map((u) => {
            return {
              ...u,
              marketing: u.consents.marketing,
              terms_and_privacy: u.consents.terms_and_privacy,
            }
          })
          commit('setUsers2', users)
          commit('setUsers2TotalPage', res.data.meta.pagination.pages)
          commit('setUsers2TotalUser', res.data.meta.pagination.total)
        } else {
          commit('setUsers2', [])
          commit('setUsers2TotalUser', 0)
        }
        commit('setIsLoadingUsers2', false)
      })
      .catch((err) => {
        // alert(err);
        commit('setIsLoadingUsers2', false)
      })
  },
  getAllUsers: async (
    { commit, getters, dispatch },
    {
      os = null,
      gender = null,
      user_type = null,
      period_type = null,
      relationship = null,
      plan = null,
      sign_in_method = null,
      province = null,
      start_premium_expire_date = null,
      end_premium_expire_date = null,
      start_age = null,
      end_age = null,
      start_created_at = null,
      end_created_at = null,
      start_date_of_birth = null,
      end_date_of_birth = null,
    }
  ) => {
    let params = {
      os: os,
      gender: gender,
      user_type: user_type,
      period_type: period_type,
      relationship: relationship,
      plan: plan,
      sign_in_method: sign_in_method,
      province: decodeURIComponent(province),
      start_age: start_age,
      end_age: end_age,
      start_premium_expire_date: start_premium_expire_date
        ? moment(start_premium_expire_date).format('yyyy-MM-DD')
        : '',
      end_premium_expire_date: end_premium_expire_date
        ? moment(end_premium_expire_date).format('yyyy-MM-DD')
        : '',
      start_created_at: start_created_at
        ? moment(start_created_at).format('yyyy-MM-DD')
        : '',
      end_created_at: end_created_at
        ? moment(end_created_at).format('yyyy-MM-DD')
        : '',
      start_date_of_birth: start_date_of_birth
        ? moment(start_date_of_birth).format('yyyy-MM-DD')
        : '',
      end_date_of_birth: end_date_of_birth
        ? moment(end_date_of_birth).format('yyyy-MM-DD')
        : '',
    }

    params = cleanParams(params)

    commit('setIsExportingUser', true)
    await axios
      .get('/download-users', {
        params: params,
        responseType: 'blob',
      })
      .then((res) => {
        commit('setIsExportingUser', false)
        const blob = new Blob([res.data], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'users.csv')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
        dispatch('setAlertMessage', {
          message: 'ดาวน์โหลดเสร็จสิ้น',
          type: 'success',
        })
      })
      .catch((err) => {
        commit('setIsExportingUser', false)
        if (err.response.status == 404) {
          dispatch('setAlertMessage', {
            message: 'Result not found',
            type: 'warning',
          })
        } else {
          dispatch('setAlertMessage', {
            message: 'Server Error',
            type: 'red darken-3',
          })
        }
      })
  },
  getUsersCountNewUser: async (
    { commit, getters },
    { start = null, end = null }
  ) => {
    commit('setIsLoadingUsersCountNewUser', true)
    let params = {
      start: start,
      end: end,
    }
    await axios
      .get('/users/count/new-user', {
        params: params,
      })
      .then((res) => {
        if (res.data !== null) {
          commit('setUsersCountNewUser', res.data)
        } else {
          commit('setUsersCountNewUser', [])
        }
        commit('setIsLoadingUsersCountNewUser', false)
      })
      .catch((err) => {
        // alert(err);
        commit('setIsLoadingUsersCountNewUser', false)
      })
  },
  getUsersCountAge: async (
    { commit, getters },
    { start = null, end = null }
  ) => {
    commit('setIsLoadingUsersCountAge', true)
    let params = {
      start: start,
      end: end,
    }
    await axios
      .get('/users/count/age', {
        params: params,
      })
      .then((res) => {
        if (res.data !== null) {
          commit('setUsersCountAge', res.data)
        } else {
          commit('setUsersCountAge', [])
        }
        commit('setIsLoadingUsersCountAge', false)
      })
      .catch((err) => {
        // alert(err);
        commit('setIsLoadingUsersCountAge', false)
      })
  },
  getUsersCountGender: async (
    { commit, getters },
    { start = null, end = null }
  ) => {
    commit('setIsLoadingUsersCountGender', true)
    let params = {
      start: start,
      end: end,
    }
    await axios
      .get('/users/count/gender', {
        params: params,
      })
      .then((res) => {
        if (res.data !== null) {
          commit('setUsersCountGender', res.data)
        } else {
          commit('setUsersCountGender', [])
        }
        commit('setIsLoadingUsersCountGender', false)
      })
      .catch((err) => {
        // alert(err);
        commit('setIsLoadingUsersCountGender', false)
      })
  },
  getUsersCountRegisterMethod: async (
    { commit, getters },
    { start = null, end = null }
  ) => {
    commit('setIsLoadingUsersCountRegisterMethod', true)
    let params = {
      start: start,
      end: end,
    }
    await axios
      .get('/users/count/register-method', {
        params: params,
      })
      .then((res) => {
        if (res.data !== null) {
          commit('setUsersCountRegisterMethod', res.data)
        } else {
          commit('setUsersCountRegisterMethod', [])
        }
        commit('setIsLoadingUsersCountRegisterMethod', false)
      })
      .catch((err) => {
        // alert(err);
        commit('setIsLoadingUsersCountRegisterMethod', false)
      })
  },
  getUsersCountRelationship: async (
    { commit, getters },
    { start = null, end = null }
  ) => {
    commit('setIsLoadingUsersCountRelationship', true)
    let params = {
      start: start,
      end: end,
    }
    await axios
      .get('/users/count/relationship', {
        params: params,
      })
      .then((res) => {
        if (res.data !== null) {
          commit('setUsersCountRelationship', res.data)
        } else {
          commit('setUsersCountRelationship', [])
        }
        commit('setIsLoadingUsersCountRelationship', false)
      })
      .catch((err) => {
        // alert(err);
        commit('setIsLoadingUsersCountRelationship', false)
      })
  },
}

const getters = {
  users2: (state) => {
    return state.users2
  },
  users2TotalPage: (state) => {
    return state.users2TotalPage
  },
  users2TotalUser: (state) => {
    return state.users2TotalUser
  },
  isLoadingUsers2: (state) => {
    return state.isLoadingUsers2
  },
  allUsers: (state) => {
    return state.allUsers
  },
  usersCountNewUser: (state) => {
    return state.usersCountNewUser
  },
  usersCountAge: (state) => {
    return state.usersCountAge
  },
  usersCountGender: (state) => {
    return state.usersCountGender
  },
  usersCountRegisterMethod: (state) => {
    return state.usersCountRegisterMethod
  },
  usersCountRelationship: (state) => {
    return state.usersCountRelationship
  },
  isLoadingUsersCountNewUser: (state) => {
    return state.isLoadingUsersCountNewUser
  },
  isLoadingUsersCountAge: (state) => {
    return state.isLoadingUsersCountAge
  },
  isLoadingUsersCountGender: (state) => {
    return state.isLoadingUsersCountGender
  },
  isLoadingUsersCountRegisterMethod: (state) => {
    return state.isLoadingUsersCountRegisterMethod
  },
  isLoadingUsersCountRelationship: (state) => {
    return state.isLoadingUsersCountRelationship
  },
  isExportingUser: (state) => {
    return state.isExportingUser
  },
}

function cleanParams(params) {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([_, v]) => v !== null && v !== undefined && v !== ''
    )
  )
}

export default {
  state,
  mutations,
  actions,
  getters,
}
