import axios from '@/api/axios-feedback'

const state = {
  feedback: [],
  currentFeedbackPage: 1,
  totalFeedbackPage: 1,
  feedbackPageSize: 25,
  isLoadingFeedback: false,
}

const getters = {
  feedback: (state) => {
    return state.feedback
  },
  currentFeedbackPage: (state) => {
    return state.currentFeedbackPage
  },
  totalFeedbackPage: (state) => {
    return state.totalFeedbackPage
  },
  isLoadingFeedback: (state) => {
    return state.isLoadingFeedback
  },
  feedbackPageSize: (state) => {
    return state.feedbackPageSize
  },
}

const mutations = {
  setFeedback(state, requests) {
    state.feedback = requests
  },
  clearFeedback(state) {
    state.feedback = []
  },
  setFeedbackPage(state, page) {
    state.currentFeedbackPage = page
  },
  setTotalFeedbackPage(state, totalFeedbackPage) {
    state.totalFeedbackPage = totalFeedbackPage
  },
  setLoadingFeedback(state, status) {
    state.isLoadingFeedback = status
  },
}
const actions = {
  getFeedback: async ({ dispatch, commit, getters }, { type, ...params }) => {
    switch (type) {
      case 'general':
        dispatch('getGeneralFeedback')
        break
      case 'monthly-horoscope':
        params.feature = 'monthly_prophecy'
        dispatch('getMonthlyHoroscopeFeedback', params)
        break
      case 'profile':
        params.feature = 'profile_v2'
        dispatch('getProfileFeedback', params)
        break
      case 'day-master':
        params.feature = 'bazi_sharing'
        dispatch('getDayMasterFeedback', params)
        break
      case 'birth-element':
        params.feature = 'base_element_info'
        dispatch('getBirthElementFeedback', params)
        break
      case 'compatibility':
        params.feature = 'compatibility'
        dispatch('getCompatibilityFeedback', params)
        break
      case 'daily-luck':
        params.feature = 'daily_luck'
        dispatch('getDailyLuckFeedback', params)
        break
      default:
        break
    }
  },
  getGeneralFeedback: async ({ commit, getters }) => {
    commit('setLoadingFeedback', true)
    await axios.get('/feedbacks').then((res) => {
      let feedback = res.data.map((d) => {
        return {
          ...d,
          ...d.response,
          created_at: getDate(d.created_at),
        }
      })
      commit('setFeedback', feedback)
      commit('setLoadingFeedback', false)
    })
  },
  getMonthlyHoroscopeFeedback: async (
    { commit, getters },
    { initSearch, feature }
  ) => {
    commit('setLoadingFeedback', true)
    let params = {
      page: getters.currentFeedbackPage,
      limit: 10,
    }
    if (initSearch) {
      params['page'] = 1
      commit('setFeedbackPage', 1)
    }
    if (!isEmpty(feature)) params.feature = feature
    await axios
      .get('/feature-feedbacks', {
        params: params,
      })
      .then((res) => {
        let feedback = res.data.data.map((d) => {
          return {
            ...d,
            ...d.response,
            created_at: getDate(d.created_at),
          }
        })
        commit('setFeedback', feedback)
        // commit('setFeedbackPage', res.data.meta.pagination.pages);
        commit('setTotalFeedbackPage', res.data.meta.pagination.pages)
        commit('setLoadingFeedback', false)
      })
  },
  getProfileFeedback: async ({ commit, getters }, { initSearch, feature }) => {
    commit('setLoadingFeedback', true)
    let params = {
      page: getters.currentFeedbackPage,
      limit: 10,
    }
    if (initSearch) {
      params['page'] = 1
      commit('setFeedbackPage', 1)
    }
    if (!isEmpty(feature)) params.feature = feature
    await axios
      .get('/feature-feedbacks', {
        params: params,
      })
      .then((res) => {
        let feedback = res.data.data.map((d) => {
          return {
            ...d,
            ...d.response,
            created_at: getDate(d.created_at),
          }
        })
        commit('setFeedback', feedback)
        // commit('setFeedbackPage', res.data.meta.pagination.pages);
        commit('setTotalFeedbackPage', res.data.meta.pagination.pages)
        commit('setLoadingFeedback', false)
      })
  },
  getDayMasterFeedback: async (
    { commit, getters },
    { initSearch, feature }
  ) => {
    commit('setLoadingFeedback', true)
    let params = {
      page: getters.currentFeedbackPage,
      limit: 10,
    }
    if (initSearch) {
      params['page'] = 1
      commit('setFeedbackPage', 1)
    }
    if (!isEmpty(feature)) params.feature = feature
    await axios
      .get('/feature-feedbacks', {
        params: params,
      })
      .then((res) => {
        let feedback = res.data.data.map((d) => {
          return {
            ...d,
            ...d.response,
            created_at: getDate(d.created_at),
          }
        })
        commit('setFeedback', feedback)
        // commit('setFeedbackPage', res.data.meta.pagination.pages);
        commit('setTotalFeedbackPage', res.data.meta.pagination.pages)
        commit('setLoadingFeedback', false)
      })
  },
  getBirthElementFeedback: async (
    { commit, getters },
    { initSearch, feature }
  ) => {
    commit('setLoadingFeedback', true)
    let params = {
      page: getters.currentFeedbackPage,
      limit: 10,
    }
    if (initSearch) {
      params['page'] = 1
      commit('setFeedbackPage', 1)
    }
    if (!isEmpty(feature)) params.feature = feature
    await axios
      .get('/feature-feedbacks', {
        params: params,
      })
      .then((res) => {
        let feedback = res.data.data.map((d) => {
          return {
            ...d,
            ...d.response,
            created_at: getDate(d.created_at),
          }
        })
        commit('setFeedback', feedback)
        // commit('setFeedbackPage', res.data.meta.pagination.pages);
        commit('setTotalFeedbackPage', res.data.meta.pagination.pages)
        commit('setLoadingFeedback', false)
      })
  },
  getCompatibilityFeedback: async (
    { commit, getters },
    { initSearch, feature }
  ) => {
    commit('setLoadingFeedback', true)
    let params = {
      page: getters.currentFeedbackPage,
      limit: 10,
    }
    if (initSearch) {
      params['page'] = 1
      commit('setFeedbackPage', 1)
    }
    if (!isEmpty(feature)) params.feature = feature
    await axios
      .get('/feature-feedbacks', {
        params: params,
      })
      .then((res) => {
        let feedback = res.data.data.map((d) => {
          return {
            ...d,
            ...d.response,
            created_at: getDate(d.created_at),
          }
        })
        commit('setFeedback', feedback)
        // commit('setFeedbackPage', res.data.meta.pagination.pages);
        commit('setTotalFeedbackPage', res.data.meta.pagination.pages)
        commit('setLoadingFeedback', false)
      })
  },
  getDailyLuckFeedback: async (
    { commit, getters },
    { initSearch, feature }
  ) => {
    commit('setLoadingFeedback', true)
    let params = {
      page: getters.currentFeedbackPage,
      limit: 10,
    }
    if (initSearch) {
      params['page'] = 1
      commit('setFeedbackPage', 1)
    }
    if (!isEmpty(feature)) params.feature = feature
    await axios
      .get('/feature-feedbacks', {
        params: params,
      })
      .then((res) => {
        let feedback = res.data.data.map((d) => {
          return {
            ...d,
            ...d.response,
            created_at: getDate(d.created_at),
          }
        })
        commit('setFeedback', feedback)
        // commit('setFeedbackPage', res.data.meta.pagination.pages);
        commit('setTotalFeedbackPage', res.data.meta.pagination.pages)
        commit('setLoadingFeedback', false)
      })
  },
}
function isEmpty(obj) {
  return typeof obj == 'undefined' || obj == '' || obj == null || obj == '-'
}

function getDate(date) {
  const f = new Intl.DateTimeFormat('th', {
    dateStyle: 'long',
  })
  return f.format(new Date(date))
}
export default {
  state,
  mutations,
  actions,
  getters,
}
