import axios from '@/api/axios-ads.js'
const state = {
  advertisements: [],
  advertisement: null,
  totalAdsPage: 1,
  currentAdsPage: 1,
  totalAds: 0,
  isLoadingAds: false,
  adsFormList: {
    customer: [],
    ads_type_list: [],
    ad_order: [],
    ad_position: [],
    auspicious_type: [],
    countries: [],
    provinces: [],
    mothns: [],
  },
}

const getters = {
  advertisements: (state) => {
    return state.advertisements
  },
  advertisement: (state) => {
    return state.advertisement
  },
  totalAdsPage: (state) => {
    return state.totalAdsPage
  },
  currentAdsPage: (state) => {
    return state.currentAdsPage
  },
  totalAds: (state) => {
    return state.totalAds
  },
  isLoadingAds: (state) => {
    return state.isLoadingAds
  },
  adsFormList: (state) => {
    return state.adsFormList
  },
}

const mutations = {
  // Authorize
  setAdvertisements(state, ads) {
    state.advertisements = ads
  },
  setAdvertisement(state, ad) {
    state.advertisement = ad
  },
  clearCurrentAd(state) {
    state.advertisement = null
  },
  setAdsPage(state, page) {
    state.currentAdsPage = page
  },
  setTotalAds(state, totalAds) {
    state.totalAds = totalAds
  },
  setTotalAdsPage(state, totalAdsPage) {
    state.totalAdsPage = totalAdsPage
  },
  isLoadingAds(state, status) {
    state.isLoadingAds = status
  },
  updateFormList(state, { key, value }) {
    state.adsFormList[key] = value
  },
}

const actions = {
  getAdvertisements: async (
    { commit, getters },
    { status = null, type, date_range, isSearching }
  ) => {
    commit('isLoadingAds', true)
    let params = {
      page: getters.currentAdsPage,
      limit: 10,
    }
    if (isSearching) {
      params['page'] = 1
      commit('setAdsPage', 1)
    }
    if (!isEmpty(status)) params.status = status
    if (!isEmpty(type)) params.type = type
    if (!isEmpty(date_range)) params.date_range = date_range
    axios.get('/ads').then((res) => {
      if (res.data.ads !== null) {
        let data = addExpirationTime(res.data.ads)
        commit('setAdvertisements', data)
      } else commit('setAdvertisements', [])
      commit('setTotalAds', res.data.meta.pagination.total)
      commit('setTotalAdsPage', res.data.meta.pagination.pages)
      commit('isLoadingAds', false)
    })
  },
  getAdvertisement: async ({ commit, dispatch, getters }, id) => {
    axios.get('/ads/' + id).then((res) => {
      let data = res.data
      let {
        start_date,
        end_date,
        ad_type,
        ad_position,
        customer: customerName,
      } = data
      let startDate = getDateFromISODate(start_date)
      let startTime = getTimeFromISODate(start_date)
      let endDate = getDateFromISODate(end_date)
      let endTime = getTimeFromISODate(end_date)
      let customers = getters.adsFormList.customer
      let customerNamedId = customers.find((c) => c.name == customerName)
      let formatted = {
        ...data,
        start_date: startDate,
        start_time: startTime,
        end_date: endDate,
        end_time: endTime,
      }
      if (ad_type == 'banner') {
        dispatch('getAvailableAdOrderFromAdPosition', ad_position)
      }
      commit('setAdvertisement', formatted)
    })
  },
  createNewAds: async ({ commit, dispatch }, { ...form }) => {
    let startDate = getDateFromDateAndTime(form.start_date, form.start_time)
    let endDate = getDateFromDateAndTime(form.end_date, form.end_time)
    form = {
      ...form,
      start_date: startDate,
      end_date: endDate,
      start_age: Number(form.start_age),
      end_age: Number(form.end_age),
      ad_order: Number(form.ad_order),
    }
    let destructForm = form
    if (form.ad_type !== 'banner') {
      let { ad_order, ad_position, auspicious_type, ...newForm } = form
      destructForm = newForm
    }
    axios
      .post('/ads', { ...destructForm })
      .then((res) => {
        dispatch('setAlertMessage', {
          message: 'เพิ่มโฆษณาแล้ว',
          type: 'success',
        })
        commit('clearCurrentAd')
      })
      .catch((res) => {
        let errorMessage = res.response.data.error
        dispatch('setAlertMessage', {
          message: `error: ${errorMessage}`,
          type: 'red darken-3',
        })
      })
  },

  copyAd: async ({ commit, dispatch, getters }, { ...form }) => {
    let destructForm = form
    let { created_at, updated_at, id, ...newForm } = form
    destructForm = newForm

    let name = destructForm.name

    function isInteger(value) {
      return /^\d+$/.test(value)
    }

    if (
      typeof name[name.length - 2] != 'undefined' &&
      isInteger(name[name.length - 2])
    ) {
      let newName = name.split('')
      newName[newName.length - 2] = parseInt(newName[newName.length - 2]) + 1
      name = newName.join('')
    } else {
      name = name + '(1)'
    }
    destructForm.name = name

    await axios
      .post('/ads', { ...destructForm })
      .then((res) => {
        dispatch('setAlertMessage', {
          message: 'คัดลอกโฆษณาแล้ว',
          type: 'success',
        })
        commit('clearCurrentAd')
      })
      .catch((res) => {
        let errorMessage = res.response.data.error
        dispatch('setAlertMessage', {
          message: `error: ${errorMessage}`,
          type: 'red darken-3',
        })
      })
  },

  editAds: async ({ commit, dispatch }, { form, adsId }) => {
    let startDate = getDateFromDateAndTime(form.start_date, form.start_time)
    let endDate = getDateFromDateAndTime(form.end_date, form.end_time)
    form = {
      ...form,
      start_date: startDate,
      end_date: endDate,
      start_age: Number(form.start_age),
      end_age: Number(form.end_age),
      ad_order: Number(form.ad_order),
    }
    let destructForm = form
    if (form.ad_type !== 'banner') {
      let { ad_order, ad_position, auspicious_type, ...newForm } = form
      destructForm = newForm
    }
    if (form.ad_type !== 'reward') {
      destructForm.media_type = 'image'
    }
    axios
      .put('/ads/' + adsId, { ...destructForm })
      .then((res) => {
        dispatch('setAlertMessage', {
          message: 'แก้ไขโฆษณาแล้ว',
          type: 'success',
        })
        dispatch('getAdvertisement', adsId)
      })
      .catch((res) => {
        this.$store.dispatch('setAlertMessage', {
          message: 'เกิดข้อผิดพลาด',
          type: 'red darken-3',
        })
      })
  },
  deleteAds: async ({ commit, dispatch }, id) => {
    axios
      .delete('/ads/' + id)
      .then((res) => {
        dispatch('setAlertMessage', { message: 'ลบโฆษณาแล้ว', type: 'success' })
        dispatch('getAdvertisements', {})
      })
      .catch((res) => {
        this.$store.dispatch('setAlertMessage', {
          message: 'เกิดข้อผิดพลาด',
          type: 'red darken-3',
        })
      })
  },
  setAdsPage: async ({ commit }, pageNumber) => {
    commit('setAdsPage', pageNumber)
  },

  getAvailableAdOrderFromAdPosition: async ({ commit }, adPosition) => {
    axios
      .get('/select-options/ad-order', {
        params: {
          ad_position: adPosition,
        },
      })
      .then((res) => {
        commit('updateFormList', { key: 'ad_order', value: res.data })
      })
  },

  initializeFormList: async ({ commit }) => {
    axios.get('/customers').then((res) => {
      let customer = res.data.map((c) => {
        return {
          ...c,
          namedId: `${c.id} ${c.name}`,
        }
      })
      commit('updateFormList', { key: 'customer', value: customer })
    })
    axios.get('/select-options/ad-status').then((res) => {
      commit('updateFormList', { key: 'ad_status', value: res.data })
    })
    axios.get('/select-options/ad-type').then((res) => {
      commit('updateFormList', { key: 'ad_type', value: res.data })
    })
    axios.get('/select-options/ad-position').then((res) => {
      commit('updateFormList', { key: 'ad_position', value: res.data })
    })
    axios.get('/select-options/auspicious-type').then((res) => {
      let data = res.data.filter((p) => p.id != 'all')
      commit('updateFormList', { key: 'auspicious_type', value: data })
    })
    axios.get('/checkboxes/months').then((res) => {
      commit('updateFormList', { key: 'months', value: res.data })
    })
    axios.get('/checkboxes/countries').then((res) => {
      let replace = []
      if (res.data.length > 0) {
        let thailand = res.data.find((t) => t.name.toLowerCase() == 'thailand')
        let data = res.data.filter((a) => a.name.toLowerCase() !== 'thailand')
        replace = [thailand, ...data]
        commit('updateFormList', { key: 'thailandID', value: thailand.id })
      }
      commit('updateFormList', { key: 'countries', value: replace })
    })
    axios.get('/checkboxes/countries/Thailand/provinces').then((res) => {
      commit('updateFormList', { key: 'provinces', value: res.data.provinces })
    })
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}

function isEmpty(obj) {
  return typeof obj == 'undefined' || obj == '' || obj == null || obj == '-'
}

function getDateFromDateAndTime(dateString, timeString) {
  // Split the date and time strings
  const dateParts = dateString.split('-')
  const timeParts = timeString.split(':')

  // Extract year, month, day, hour, and minute from the split parts
  const year = parseInt(dateParts[0])
  const month = parseInt(dateParts[1]) - 1 // Months are zero-based (0-11)
  const day = parseInt(dateParts[2])
  const hour = parseInt(timeParts[0])
  const minute = parseInt(timeParts[1])

  const combinedDate = new Date(year, month, day, hour, minute)
  return combinedDate.toISOString()
}

function addExpirationTime(ads) {
  const getExpireTime = (a, b) => {
    let timeDiff = new Date(b) - new Date(a)
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24))
  }
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  })
  const getDisplayPeriod = (a, b) => {
    return `${dateFormatter.format(new Date(a))} - ${dateFormatter.format(new Date(b))}`
  }
  let data = ads.map((a) => {
    return {
      ...a,
      expire: `${getExpireTime(a.start_date, a.end_date)} Days`,
      display_period: getDisplayPeriod(a.start_date, a.end_date),
    }
  })
  return data
}

function getDateFromISODate(d) {
  let date = new Date(d)
  const thailandOffsetMinutes = 7 * 60 // UTC+7
  date.setMinutes(date.getMinutes() + thailandOffsetMinutes)
  return date.toISOString()
}

function getTimeFromISODate(d) {
  let date = new Date(d)
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZoneName: 'short',
    timeZone: 'Asia/Bangkok',
  }
  const localTime = new Intl.DateTimeFormat(undefined, options).formatToParts(
    date
  )

  const hourPart = localTime.find((part) => part.type === 'hour')
  const minutePart = localTime.find((part) => part.type === 'minute')
  let time = `${hourPart.value == '24' ? '00' : hourPart.value}:${minutePart.value}`
  return time
}
