export const auspiciousTypes = [
  {
    en: 'New Wallet',
    th: 'ฤกษ์กระเป๋าสตางค์',
    value: 'new_wallet',
  },
  {
    en: 'Examination',
    th: 'ฤกษ์สอบ',
    value: 'exam',
  },
  {
    en: 'Marriage',
    th: 'ฤกษ์แต่งงาน',
    value: 'marriage',
  },
  {
    en: 'Marriage Registration',
    th: 'Marriage Registration',
    value: 'marriage_registration',
  },
  {
    en: 'New Car',
    th: 'ฤกษ์ออกรถ',
    value: 'new_car',
  },
  {
    en: 'Birth',
    th: 'ฤกษ์คลอดบุตร',
    value: 'birth',
  },
  {
    en: 'House Moving',
    th: 'ฤกษ์ขึ้นบ้านใหม่',
    value: 'house_moving',
  },
  {
    en: 'House Construction',
    th: 'ฤกษ์พิธีลงเสาเอกอาคารทั่วไป',
    value: 'non_residential_construction',
  },
  {
    en: 'Building Construction',
    th: 'ฤกษ์พิธีลงเสาเอกบ้าน',
    value: 'residential_construction',
  },
  {
    en: 'Business Opening',
    th: 'ฤกษ์เปิดกิจการ',
    value: 'business_opening',
  },
  {
    en: 'Contract Signing',
    th: 'ฤกษ์เซ็นสัญญา',
    value: 'contract_signing',
  },
  {
    en: 'Surgery',
    th: 'ฤกษ์ผ่าตัด',
    value: 'cosmetic_surgery',
  },
  {
    en: 'Operation',
    th: 'ฤกษ์ศัลยกรรมเพื่อความงาม',
    value: 'surgery',
  },
  {
    en: 'Treatment',
    th: 'ฤกษ์เริ่มการรักษาโรค',
    value: 'treatment',
  },
  {
    en: 'GIFT & ICSI',
    th: 'GIFT & ICSI',
    value: 'gift_icsi',
  },
  {
    en: 'Cosmetic Procedures',
    th: 'ฤกษ์เสริมความงาม',
    value: 'beauty',
  },
  {
    en: 'Studying Abroad',
    th: 'ฤกษ์เรียนต่อต่างประเทศ',
    value: 'study_abroad',
  },
  {
    en: 'Pet Adoption',
    th: 'ฤกษ์รับเลี้ยงสัตว์',
    value: 'new_pet',
  },
  {
    en: 'Product Launching',
    th: 'ฤกษ์เปิดตัวสินค้า',
    value: 'product_launch',
  },
]

export const auspiciousStatus = [
  {
    en: 'All Status',
    th: 'All Status',
    value: '',
    color: '#000000',
  },
  {
    en: 'Select Date',
    th: 'ต้องการวัน',
    value: 'auto_date_selecting',
    color: '#CC0100',
  },
  {
    en: 'Select Date',
    th: 'ต้องการวัน',
    value: 'date_selecting',
    color: '#CC0100',
  },
  {
    en: 'Waiting user to confirm',
    th: 'รอผู้ใช้ยืนยัน',
    value: 'date_confirming',
    color: '#FFBA00',
  },
  {
    en: 'Select Remaining Date',
    th: 'ต้องการเวลา',
    value: 'completing',
    color: '#F47036',
  },
  {
    en: 'Completed',
    th: 'สำเร็จ',
    value: 'completed',
    color: '#28BF7A',
  },
]

export const surgeryTypes = {
  other: 'Others',
  nose: 'Nose Surgery',
  eyes: 'Eye Surgery',
  mouth: 'Lip Surgery',
  face: 'Facial Reconstruction Surgery',
  chest: 'Breast Surgery',
  filler: 'Filler',
  botox: 'Botox',
  facial_bright_injection: 'Facial bright injection',
  meso_fat: 'Meso fat',
  intravenous_vitamin_therapy: 'Intravenous Vitamin therapy',
}
